import React from 'react'
import FeaturedReferencesTemplate from '../../components/pages/featured-referencesTemplate'
import { graphql } from 'gatsby'

const FeaturedReferencesSk = ({ data }) => (<FeaturedReferencesTemplate data={data} lang={'sk'}/>)

export const query = graphql`{
    references: file(relativePath: {eq: "pages/sk/references/featured_references.md"}) {
        childMarkdownRemark {
            frontmatter {
                description
                case_studies {
                    title
                    description
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid( maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
background_color
                    category
                    case_study
                }
            }
        }
    }
}
`


export default FeaturedReferencesSk
